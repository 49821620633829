.app{
  width: 100%;
  height: 100%;
  background:no-repeat url('../public/images/app_background.png');
  background-size: 100% 100%;
}

.app_body{
  position: relative;
  height: 100%;
  max-width: 60rem;
  display: flex;
  margin:auto;
  background-color: var(--color-white);
}
.main{
  width: 60%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
}

.main::-webkit-scrollbar {
  width: 0px;
}
.main::-webkit-scrollbar-track {
  background-color: transparent;
}
.main::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.aside{
  width: 40%;
  height: 100%;
  background-color: var(--color-main-yellow);
}
.blinder{
  display:none;
  position: absolute;
  top:0;
  left: 0;
  opacity: .6;
  width: 100%;
  height: 100%;
  background-color: var(--color-dark-grey);
  z-index: 1
}
.blinder.active{
  display: block;
}

.spiner{
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}
.spiner.active{
  display: flex;
}
.spiner i{
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  background: no-repeat url('./icon/spiner.gif');
  background-size: contain;
}


@media screen and (max-width: 44rem){
  .app_body{
    max-width: 100%;
    min-width: 22.5rem;
    display: block;
    position: relative;
    overflow: hidden;
  }
  
  .main{
    width: 100%;
  }

  .aside{
    background-color: var(--color-white);
    position: absolute;
    width: 80%;
    top: 0;
    left: 100%;
    z-index: 1;
  }
  .aside.active{
    /* 100% - .aside : width */
    z-index: 2;
    left: 20%;
  }
  .spiner.active{
   top:0;
  }
}