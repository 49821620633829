.header{
    height: 4.75rem;
    position: sticky;
    top: 0;
    z-index: 2;
}
.container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-main-yellow);
    padding:.5rem 0;
}
.title{
    flex-basis:60%;
    height: 100%;
}
.title i{
    background-image: url(../../icon/title.png);
    width: 100%;
    height: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
}
.left{
    flex-basis: 20%;
}
.right{
    height:100%;
    flex-basis: 20%;
    position: relative;
}
.hamburger{
    display: none;
    color: var(--color-white);
    font-size: 2rem;
    position:absolute;
    bottom: 0;
    right:1rem;
}


@media screen and (max-width: 44rem){
    .hamburger{
        display: block;
    }
  }