/* slide */
.swiper{
    width: 100%;
}
.slide{
    width: auto !important;
}

.button{
    border: 1px solid var(--color-middle-grey);
    padding:.7rem 1rem;
    border-radius: 1rem;
    margin-right: .8rem;
    background-color: var(--color-white);
    color:var(--color-middle-grey);
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px lightgrey;
}

.selected{
    background-color: var(--color-main-yellow);
    color:var(--color-white);
    border:0;
}