.add_food{
    height: 100vh;
    background-color: var(--color-light-grey);
}
.header{
    height: 4.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-white);
}
.left{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/close.png');
    background-repeat: no-repeat;
    background-color: rgb(235, 11, 48);
    background-position: 50%;
    background-size: 60%;
    border:0;
    margin-left:0.78125rem ;
}
.middle{
    letter-spacing: .1rem;
    font-weight: 400;
    flex-grow: 1;
    padding-left: .5rem;
    overflow: auto;
}
.align_center{
    text-align: center;
}
.right{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/save.png');
    background-repeat: no-repeat;
    background-color: rgb(254, 201, 114);
    background-position: 50%;
    background-size: 60%;
    border:0;
    margin-right:0.78125rem ;
}

.block{
    margin-top: 0.625rem;
    background: var(--color-white);
    min-height: 2.5rem;
    display:flex;
    align-items: center;
    padding-left: .5rem;
}
.icon{
    width: 1.5625rem;
    height: 1.5625rem;
    background-repeat: no-repeat;
    background-color: var(--color-white);
    background-size: contain;
    border: 0;
}
.foodcup{
    background-image: url('../../icon/foodcup.png');
}
.clock{
    background-image: url('../../icon/clock.png');
    transform: scale(.9);
    align-self:flex-start;
    margin-top:.5rem;
}
.food_type{
    width: 100%;
    margin-right: .5rem;
}
.food_grps{
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
}
.food_grps li{
    background-color: var(--color-light-grey);
    color:var(--color-dark-grey);
    border:0;
    border-radius:.5rem;
    padding: .3rem 1rem;
    margin-right: .5rem;
    margin-bottom: .5rem;
}
.food_grps li.selected{
    border: 1px solid var(--color-dark-grey);
    background-color: transparent;
    color:var(--color-dark-grey);
}

.keep_date_title, .expired_title{
    margin-top:.5rem;
}
.keep_date, .expired_date{
    letter-spacing: 0;
 font-size: var(--font-size-small);
 color:var(--color-dark-grey);
}
.calendar_holder{
    overflow: hidden;
}
.chat{
    background-image: url('../../icon/chat.png');
}
.memo{
    background-image: url('../../icon/memo.png');
    transform: scale(.9);
}
.ask, .right_arrow{
    margin-right: .5rem;
    line-height: 1rem;
    color:var(--color-dark-grey);
    font-size: var(--font-size-small);
}
.ask i{
    background-image: url(../../icon/vertical_dot.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 0.8125rem;
    height: 1rem;
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
}
.input_name{
    height: 1.6rem;
    margin-left: auto;
    padding-right:1rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid var(--color-light-grey);
    font-weight: 200;
    font-size: var(--font-size-small);
    width: 63%;
    text-align: right;
    outline: none;
}
.btn_save_name{
    width: 1.2rem;
    height: 1.2rem;
    background-image: url('../../icon/small_save.png');
    background-size: 90%;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    margin-left: .5em;
    margin-right:.5em
}
.right_arrow::after{
    color:var(--color-dark-grey);
    font-size: (--font-size-small);
    content:" > ";
    font-weight: 500;
    margin-right: .5rem;
}
.button_box{
    width: 100%;
}
.delete{
    display: block;
    border:0;
    border-radius: .4rem;
    background-color: rgb(235, 11, 48);
    color:var(--color-white);
    font-weight: 900;
    padding:.8rem 3rem;
    margin: .5rem auto;
}
.mart0{
    margin-top:0
}