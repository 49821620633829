.my_edit{
    height: 100vh;
    background-color: var(--color-light-grey);
}
.header{
    height: 4.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-white);
    position: relative;
    border-bottom: 1px solid var(--color-middle-grey);
}
.middle{
    letter-spacing: .1rem;
    font-weight: 400;
}
.align_center{
    text-align: center;
}
.right{
    position:absolute;
    right: 0;
}
.right button{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/back.png');
    background-repeat: no-repeat;
    background-color: rgb(254, 201, 114);
    background-position: 50%;
    background-size: 60%;
    border:0;
    margin-right:0.78125rem ;  
}
.email{
    text-align: center;
    padding:1.5rem;
    font-weight: 400;
}
.block{
    background: var(--color-white);
    min-height: 2.5rem;
    border-top: 1px solid var(--color-dark-grey);
    border-bottom: 1px solid var(--color-dark-grey);
}
.block:last-of-type{
    display: flex;
    justify-content: center;
    height: 3.3rem;
    margin-top: 2rem;
}
.logout{
    padding:0 2rem;
    border:0;
    background-color: transparent;
    color:red;
    font-size: var(--font-size-regular);
}
.info_box{
    display: flex;
    align-items: center;
    width: 100%;
}
.info{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-dark-grey);
    padding: 0 1rem;
    line-height: 3.3rem;
}
.info.last{
    border-bottom: 0;
}

.info_title{
    font-weight: 600;
}
.info_value{
    line-height: 3rem;
}
.icon_name , .icon_freezer, .icon_level{
    padding:0 .5rem;
}
.icon_name i{
    display: block;
    width: 2rem;
    height: 2rem;
    
    background:no-repeat url('../../icon/user.png');
    background-size: contain;
}

.icon_freezer i{
    display: block;
    width: 2rem;
    height: 2rem;
    
    background:no-repeat url('../../icon/freezer.png');
    background-size: contain;
    background-position: 50%;
}

.icon_level i{
    display: block;
    width: 2rem;
    height: 2rem;
    transform: scale(.8);
    background:no-repeat url('../../icon/level.png');
    background-size: contain;
    background-position: 50%;
}