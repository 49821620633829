.my_freezer{
    position:relative;
    height: 100%;
    background-color: var(--color-light-grey);
    overflow-y: hidden;
}
.header{
    height: 4.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-white);
    position: relative;
    border-bottom: 1px solid var(--color-middle-grey);
}
.middle{
    letter-spacing: .1rem;
    font-weight: 400;
}
.align_center{
    text-align: center;
}
.right{
    position:absolute;
    right: 0;
}
.right button{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/back.png');
    background-repeat: no-repeat;
    background-color: rgb(254, 201, 114);
    background-position: 50%;
    background-size: 60%;
    border:0;
    margin-right:0.78125rem ;  
}
.create_date{
    padding:.5rem 1rem;
}
.block_container{
    overflow-y: scroll;
    height: calc(100% - 4.75rem);
}
.block{
    margin-top:.5em;
    background: var(--color-white);
    min-height: 2.5rem;
    border-bottom: 1px solid var(--color-middle-grey);
}
.edit_freezer{
    display: flex;
    height: 4rem;
    align-items: center;
    padding:0 .5rem;
}

.icon_freezer{
    display: block;
    width: 2.3rem;
    height: 2.3rem;
    
    background:no-repeat url('../../icon/freezer.png');
    background-size: contain;
    background-position: 50%;
}
.checkbox{
    display:none;
}
.checkbox_for{
    width: 25px;
    height: 25px;
    border-radius: 6px;
    margin-left: 10px;
}
.edit_freezer.selected .checkbox_for{
    background-image: url('../../icon/check.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 60%;
}

.input_name{
    height: 1.6rem;
    margin-left: auto;
    padding-right:1rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid var(--color-light-grey);
    font-weight: 200;
    font-size: var(--font-size-small);
    width: 63%;
    text-align: right;
    outline: none;
}
.input_name.small       {
    width: 30%;
}
.name{
    width: 60%;
    line-height: 4rem;
    margin-left: .5rem;
    margin-right: auto;
}
.btn_save_name{
    width: 1.2rem;
    height: 1.2rem;
    background-image: url('../../icon/small_save.png');
    background-size: 90%;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    margin-left: .5em;
    margin-right:.5em
}
.btn_edit{
    color:rgb(63, 168, 244);
    width: 3rem;
    height: 1.6875rem;
}

.section{
    display: flex;
    padding: 1rem .5rem 1rem 0; 
    width: 88%;
    border-bottom: 1px solid var(--color-middle-grey);
    margin-left: auto;
    align-items: center;
}
.section:last-child{
    border-bottom: 0
}
.rec_icon{
    width: 20px;
    height: 20px;
    display: block;
    margin-right:.5rem
}
.section_name{
    margin-right: auto;
    font-size: var(--font-size-small);
}
.section_date{
    font-size: var(--font-size-small);
    color:var(--color-dark-grey);
    
}

.blinder{
    display: none;
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .6;
    background-color: var(--color-dark-grey);
    z-index: 0;
}
.blinder.on{
    display:block;
}
.section_add{
    bottom: 7%;
    right: 8%;
    position: absolute;
    z-index: 10;
    height: 5rem;
}
.btn_add{
    background: no-repeat url('../../icon/plus_white.png');
    background-size: 50%;
    background-position: 50%;
    border:0;
    background-color: var(--color-main-yellow);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    box-shadow: 0 10px 15px -7px rgb(0 0 0 / 20%);
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-tap-highlight-color: transparent;
}
.btn_add.on {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.btn_box{
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateY(25rem);
    transition: all 200ms ease-in-out;
}

.btn_box.on{
    transform: translateY(-8.125rem);
}
.btn_freezer{
    background: no-repeat url('../../icon/freezer_white.png');
    background-size: 40%;
    background-position: 50%;
    border:0;
    background-color: var(--color-white);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: .3rem;
}
.btn_section{
    background: no-repeat url('../../icon/drawer_white.png');
    background-size: 50%;
    background-position: 50%;
    border:0;
    background-color: var(--color-white);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
}