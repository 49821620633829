:root{
  --color-main-yellow: rgb(255, 200, 20);
  --color-main-text:#000000;
  --color-white:#ffffff;
  --color-light-grey:#f1f1f5;
  --color-middle-grey:rgb(191, 191, 191);
  --color-dark-grey:#4a4a4b;

  --font-size-big:2rem;
  --font-size-larger: 1.2rem;
  --font-size-regular: 1rem;
  --font-size-small:.8rem;
  --font-size-micro:.5rem;

  --height-header:4.75rem;
  --height-each-section:3.25rem;
}

*{box-sizing: border-box;margin:0;padding:0;}
ul{list-style-type: none;}
html , #root{
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family:'Nanum Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:visited, a:link{
  /* text-decoration:none; */
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 30px);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}


.color1{
  background-color: rgb(255, 197, 108);
}
.color2{
  background-color: rgb(255, 157, 108);
}
.color3{
  background-color: rgb(255, 140, 106);
}
.color4{
  background-color:rgb(255, 128, 136)
}
.color5{
  background-color:rgb(251, 152, 186);
}
.color6{
  background-color:rgb(238, 126, 168);
}
.color7{
  background-color:rgb(234, 156, 218);
}
.color8{
  background-color:rgb(160, 174, 230);
}
.color9{
  background-color:rgb(154, 202, 236);
}
.color10{
  background-color:rgb(135, 218, 223);
}
.color11{
  background-color:rgb(105, 202, 186);
}
.color12{
  background-color:rgb(183, 220, 120);
}
.color0{
  background-color:rgb(63, 168, 244);
}
