
.food_container{
    width: 100%;
    background-color: antiquewhite;
}
.outline{
    width: 100%;
    padding:.25rem 0.5rem;
    background-color: var(--color-light-grey);
}
.food{
    display:flex;
    background-color:var(--color-white);
}
.img{
    width: 6.25rem;
    height: 6.25rem;
    padding:.3rem;
}
.img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.name{
    display: inline-block;
    font-weight:400;
}
.nutrition{
    font-weight: 200;
    font-size: var(--font-size-small);
}
.info{
    flex-grow: 100;
    padding: 0.5rem 0.25rem;
    display: flex;
    flex-direction: column;
}
.memo{
    font-weight:200;
    font-size: var(--font-size-small);
    color:var(--color-dark-grey);
    margin-top:.25rem
}
.btns{
    display:flex;
    justify-content:flex-end;
    margin-top:auto;
}
.btn{
    width: 1.875rem;
    height: 1.875rem;
    margin-right: .4rem;
    border:0;
    background-size: cover;
    background-color: transparent;
}
.recipe{
    transform:scale(.95);
    background-image: url(../../../icon/recipe_off.png);
}
.recipe.active{
    transform:scale(.95);
    background-image: url(../../../icon/recipe_on.png);
}
.basket{
    transform:scale(1.1);
    background-image: url(../../../icon/basket_off.png);
}
.basket.active{
    transform:scale(1.1);
    background-image: url(../../../icon/basket_on.png);
}
.date{
    transform:scale(.91);
    background-image: url(../../../icon/date.png);
}
.date::after{
    position: absolute;
    right: -13%;
    display: inline-block;
    min-width: 18px;
    height: 18px;
    line-height: 16px;
    text-align: center;
    margin-top: -1px;
    padding: 0 4px;
    box-sizing: border-box;
    border-radius: 18px;
    color: rgb(101, 101, 101);
    font-size: 14px;
    border: 1px solid white;
    background-color: lightgray;
    content: attr(data-count);
}
.warning{
    background-image: url(../../../icon/warning_off.png);
}
.warning.active{
    background-image: url(../../../icon/warning_on.png);
}
.warning.ready{
    background-image: url(../../../icon/warning_ready.png);
}