.login{
    width: 100%;
    height: 100%;
    background-color: var(--color-main-yellow);
    display: flex;
    flex-direction:column;
}

.logo{
}
.logo i{
    display: block;
    margin:30% auto 0 auto;
    width: 100%;
    height: 10rem;
    background-size: contain;
    background-image:url('../../icon/login_logo.png');
    background-repeat: no-repeat;
    background-position: center;
}
.buttons{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
    width: 80%;
    margin:auto;
}
.button{
    margin-bottom:.2rem;
    padding:1rem 0;
    font-size: var(--font-size-regular);
    border : 0;
    border-radius: 0.4rem;
}
.facebook{
    background-color: #3477f1;
    color:#ffffff;
}
.google{
    background-color: #ffffff;
}