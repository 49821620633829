.add_detail{
    height: 100%;
    background-color: var(--color-light-grey);
    position: relative;
}
.header{
    height: 4.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-white);
}
.middle{
    letter-spacing: .1rem;
    font-weight: 400;
    flex-grow: 1;
    padding-left: .5rem;
    overflow: auto;
}
.align_center{
    text-align: center;
}
.right_holder{
    position: relative;
}
.right{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/save.png');
    background-repeat: no-repeat;
    background-color: rgb(254, 201, 114);
    background-position: 50%;
    background-size: 60%;
    border:0;
    position: absolute;
    right: 0.78125rem;
    transform: translateY(-13px);
}

.block{
    height: 63vh;
}

.detail{
    margin-top: 0.625rem;
    background-color: white;
    height: 100%;
    width: 100%;
    font-size: var(--font-size-larger);
}
.cursor {
    display: inline-block;
    width: 2px;
    height: 1em;
    vertical-align: -0.2em;
    animation: cursor linear .8s infinite;
    background: blue;
}
@keyframes cursor {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.keypad_wrapper{
    background-color: rgb(51, 51, 51);
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    padding: 0.8rem 0;
}

 .keypad_wrapper button{
    outline: 0;
    border : 0;
    margin : 0;
    border-radius: 0.4375rem;
    width: 100%;
    color: white;
    background-color: rgb(177, 177, 177);
}

 .hide_container{
    padding: 0.3125rem;
}

 .keypad_container{
    display: table;
    width: 100%;
}

 .num_container{
    width: 80%;
    float: left;
    display: table-cell;
}

 .unit_container{
    width: 20%;
    float: right;
    display: table-cell;
    box-sizing: border-box;
    padding-right: 0.5rem;
}

 .hide_container .row{
    margin: 0 auto;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
}

 .hide_container .row .col{
    vertical-align: middle;
    padding: 0px 0.125rem;
}

 .hide_container .row .col .hide_button{
    height: 65%;
    font-weight: bold;
    font-size: 1.0625rem;
}

 .num_container .row{
    width: 100%;
    padding: 0px 0.375rem;
    box-sizing: border-box;
    display:table
}

 .num_container .row .col{
    display: table-cell;
    /* height: 100%; */
    width: 33.33%;
    padding: 0.0625rem 0.125rem;
    vertical-align: middle;
}

 .num_container .row .col .num_button{
    font-size: 1.25rem;
    padding: 0.875rem 0;
}


 .unit_container .row{
    width: 100%;
}

 .unit_container .col ul{
    display: flex;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    overflow-y: auto;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style-type: none;
    height: 13.25rem;
    color: white;
    font-size: 0.9375rem;
    background-color: rgb(177, 177, 177);
    border-radius: 0.4375rem;
}

 .unit_container .col ul li::before{
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    content: "";
}

 .unit_container .col ul li{
    flex: 1;
    min-height: 1.875rem;
    text-align: center;
    padding: 0px 0.125rem;
}
