.custom_calendar{
    margin-top: .8rem;
    padding-right: 1rem;
    border:0;
    height: 0;
    transition: all 100ms ease-in-out;
   }
   .custom_calendar.active{
    height: 16.875rem;
   }
.custom_calendar .react-calendar__navigation{
 background-color: var(--color-main-yellow);
}

.react-calendar__navigation__arrow , .react-calendar__navigation__label__labelText{
    color:var(--color-white) !important;
    font-weight: 900;
    font-size: 1.5rem;
}
.react-calendar__tile--active , .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    background-color: var(--color-main-yellow);
    border-radius:1rem;
    font-weight: 900;
}
.react-calendar__tile--now{
    background-color:var(--color-light-grey);
    border-radius:1rem;
    color:var(--color-dark-grey);
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
    background-color: var(--color-main-yellow);
}