.my_basket{
    height: 100%;
    background-color: var(--color-light-grey);
}
.header{
    height: 4.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-white);
    position: relative;
    border-bottom: 1px solid var(--color-middle-grey);
}
.middle{
    letter-spacing: .1rem;
    font-weight: 400;
}
.align_center{
    text-align: center;
}
.right{
    position:absolute;
    right: 0;
}
.right button{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/back.png');
    background-repeat: no-repeat;
    background-color: rgb(254, 201, 114);
    background-position: 50%;
    background-size: 60%;
    border:0;
    margin-right:0.78125rem ;  
}
.basket_container{
    overflow-y: scroll;
    height: calc(100% - 4.75rem);
}
.block{
    margin-top:.5em;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-middle-grey);
}
.section{
    display: flex;
    padding: .3rem 1rem;
    width: 100%;
    height: 2.5rem;
    align-items: center;
}
.rec_icon{
    width: 20px;
    height: 20px;
    display: block;
    margin-right:.5rem
}
.rec_icon.checked{
    background-image: url('../../icon/check.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 60%;
}
.name{
    margin-right: auto;
}

.btn_edit{
    color:rgb(63, 168, 244);
    width: 2rem;
    height: 1.6875rem;
    padding-left: 1rem;
}
.input{
    flex-grow: 1;
    border:0;
    border-bottom: 1px solid var(--color-middle-grey);
    line-height: 1.3rem;
}
.section_add{
    bottom: 7%;
    right: 8%;
    position: absolute;
    z-index: 10;
    overflow-y: hidden;
    height: 5rem;
}
.btn_add{
    background: no-repeat url('../../icon/plus_white.png');
    background-size: 50%;
    background-position: 50%;
    border:0;
    background-color: var(--color-main-yellow);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    box-shadow: 0 10px 15px -7px rgb(0 0 0 / 20%);
    -webkit-transition: -webkit-transform .5s;
    transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    -webkit-tap-highlight-color: transparent;
}