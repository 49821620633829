
.section{
    z-index: 1;
}
.section.selected{
    margin-bottom: auto;
    position: sticky;
    background-color: var(--color-white);
}
.section.selected{
    top: var(--height-header);
}
.section + ul{
    height:0;
    overflow: hidden;
}
.section.selected + ul{
    height: auto;
    overflow-y: scroll;
}
.section.selected .container{
    box-shadow: 0px 3px 5px -2px grey;
}
.container{
    height: 3.25rem;
    display:flex;
    padding-left: .8rem;
    /* border-bottom:1px solid black; */
    border-top:1px solid var(--color-middle-grey);
    align-items:center;
}
.section:last-of-type{
    border-bottom:1px solid var(--color-middle-grey);
}
.handle{
    width: 30%;
}
.grab{
    height: 1.2rem;
    width: 7rem;
    max-width: 90%;
    border:1px solid var(--color-dark-grey);
    align-self: center;
    border-radius: .2rem;
}
.drawer_name{
    width: 30%;
    letter-spacing: .2rem;
}
.handle i{
    display: block;
    height: .2rem;
    width: 50%;
    margin:0.1em 0 0 .3rem;
    border:1px solid var(--color-dark-grey);
    align-self: center;
    border-radius: .2rem;
}
.plus{
    width: 1.8rem;
    height: 1.8rem;
    background-image: url('../../../icon/add.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50%;
    border:1px solid var(--color-dark-grey);
    border-radius: 50%;
    background-color: transparent;
    vertical-align: middle;
    
}
.add_food{
    position: relative;
    width: 40%;
    text-align: right;
    margin-right: 0.8rem;
    font-size: 1.5rem;
}
