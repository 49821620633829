
.ballon_box{
    position: absolute;
    height: 5rem;
    width: 9.375rem;
    top:2rem;
    right: 1rem;
}

.right_point{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30%;
    justify-content: space-evenly;
    align-items: flex-end;
}
.point1{
    width: 10%;
    height: 30%;
    background-color:rgb(160, 174, 230);
    border-radius: 5rem;
}
.point2{
    height: 40%;
    width: 30%;
    background-color:rgb(160, 174, 230);
    border-radius: 5rem;
}

.ballon{
    width: 95%;
    height: 2rem;  /*  height param */
    text-align: center;
    padding:.2rem .5rem;
    background-color:rgb(160, 174, 230);
    border-radius: .5rem;
    color:var(--color-white);
    font-size: var(--font-size-small);
    font-weight: 900;
    border: .25rem solid rgb(135, 218, 223);;
}


.left_point{
	width: 0;
	height: 0;
	border-left: 2vh solid transparent;
	border-right: 2vh solid transparent;
	border-top: 10vh solid var(--color-white);
	position: absolute;
	top: 45%;
	left: -10%;
	transform: rotate(60deg);
}
.confirm{
 font-size: var(--font-size-micro);   
}
.xbutton{
    width: .8rem;
    height: .8rem;
    background-image: url('../../../icon/add.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 50%;
    background-color: transparent;
    vertical-align: middle;
    border: 0;
    transform: rotate(45deg);
}