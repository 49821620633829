.section{
display:flex;
flex-direction:column;
align-items: center;
justify-content: flex-start;
height: 100%;
overflow: hidden;
}
.header{
    margin-top: 20%;
}
.me_pic{
    text-align: center;
}
.me_pic img{
    width: 3.325rem;
    height: 3.325rem;
    border:.2rem solid var(--color-dark-grey);
    border-radius: 50%;
    margin:auto;
}
.email{
    margin-top:1rem;
    border-bottom: 1px solid var(--color-dark-grey);
    color:var(--color-dark-grey);
    font-weight: bold;
    line-height: 2rem;
}
.sub_actions{
    width: 57%;
    height: 6rem;
    margin-top:2rem;
    display: flex;
    justify-content:space-between;
}
.sub_action{

}
.count{
    margin-top:1rem;
    text-align: center;
    color:var(--color-dark-grey);
}
.warning i{
    display: block;
    width: 2.125rem;
    height: 2.125rem;
    background: no-repeat url('../../icon/warning_off.png');
    background-size: contain;
}
.recipe i{
    display: block;
    width: 2rem;
    height: 2rem;
    background: no-repeat url('../../icon/recipe_off.png');
    background-size: contain;
}
.basket i{
    display: block;
    width: 2rem;
    height: 2rem;
    background: no-repeat url('../../icon/basket_off.png');
    background-size: contain;
}
.actions{
    width: 90%;
    /* height: 12rem; */
    margin-top:auto;
    margin-bottom: 10%;
    display: flex;
    flex-wrap: wrap;
}
.action{
    width:33.3%;
    display: flex;
    align-items:center;
    justify-content: center;
    height: 6rem;
}
.action button{
    width: 100%;
    border:0;
    background-color: transparent;
    color:var(--color-dark-grey);
    font-weight: 600;

}

.line_top {
    border-top:1px solid var(--color-dark-grey);
}
.line_left {
    border-left:1px solid var(--color-dark-grey);
}
.line_right {
    border-right:1px solid var(--color-dark-grey);
}

.actions i{
    display:block;
    width:30px;
    height:30px;
    margin:auto;
    margin-bottom: .5rem;
    cursor: pointer;
}

.actions button{
    cursor: pointer;
}

.my_edit i{
    background:no-repeat url('../../icon/lock.png');
    background-size:contain;
    background-position: center;
}
.my_ref i{
    background:no-repeat url('../../icon/freezer.png');
    background-size:contain;
    background-position: center;
}
.my_basket i{
    background:no-repeat url('../../icon/basket_off.png');
    background-size:contain;
    background-position: center;
}
.my_recipe i{
    background:no-repeat url('../../icon/recipe_off.png');
    background-size:contain;
    background-position: center;
}
.my_notice i{
    background:no-repeat url('../../icon/notice.png');
    background-size:contain;
    background-position: center;
}
.my_ask i{
    background:no-repeat url('../../icon/call.png');
    background-size:contain;
    background-position: center;
}
