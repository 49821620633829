.add_memo{
    height: 100vh;
    background-color: var(--color-light-grey);
}
.header{
    height: 4.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    background-color: var(--color-white);
}
.middle{
    letter-spacing: .1rem;
    font-weight: 400;
    flex-grow: 1;
    padding-left: .5rem;
    overflow: auto;
}
.align_center{
    text-align: center;
}
.right_holder{
    position: relative;
}
.right{
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../../icon/save.png');
    background-repeat: no-repeat;
    background-color: rgb(254, 201, 114);
    background-position: 50%;
    background-size: 60%;
    border:0;
    position: absolute;
    right: 0.78125rem;
    transform: translateY(-13px);
}

.block{
    margin-top:10px;
    height: calc(100% - 7rem);;
}

.memo{
    width: 100%;
    height: 100%;
    border: 0;
    outline: 0;
    font-size: var(--font-size-larger);
}

