
.what_is{
    position:absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.blinder{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-dark-grey);
    opacity: .8;
}

.container{
    height: 100%;
}

.img{
    height: 100%;
    margin: auto;
}
.confirm{
    position: absolute;
    bottom: 1%;
    z-index: 11;
    width: 100%;   
}
.button_box{
    text-align: right;
}
.button_box button{
    padding: .8rem .3rem;
    background-color: transparent;
    border: 0;
    font-weight: 600;
    color: var(--color-white);
}

.xbutton{
    width: .8rem;
    height: .8rem;
    display: inline-block;
    background-image: url('../../icon/add.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    background-color: transparent;
    vertical-align: middle;
    border: 0;
    transform: rotate(45deg);
    position: relative;
    vertical-align: text-top;
}
.img img{
    display: block;
    max-width: 100%;
    height: 100%;
    margin:auto;
}

.container .swiper-button-next{
    color:var(--color-white) !important;
}